import './css/styles.css';
import './App.css';
import { AppFooter, AppHeader, AppRoutes }                                from './AppRoutes';
import { LoadingIndicator, TwoSwitch, decodeJWT }
                                                from 'iprs-react-library';
                                                
import { useEffect, useState }                  from 'react';

import { synthetix } from '../Synthetix/synthetix.js';

// Added getURLPathArray, getURLSearchParam to aid testing
const App = ({api, getURLPathArray, getURLSearchParam}) => {

    // /* FOR TESTING ONLY! */
    // const currentUrl = window.location.href;
    // // This is to determine whether this project is being run locally or in staging
    // // .search() will give -1 if nothing is found.
    // const isLocal = currentUrl.search(/localhost/i);
    // const isStaging = currentUrl.search(/staging/i);

    // let isLocalOrStaging = "";

    // if(isLocal > -1 && isStaging === -1){
    //     isLocalOrStaging = "local";
    // } else if (isLocal === -1 && isStaging > -1){
    //     isLocalOrStaging = "staging";
    // } else {
    //     isLocalOrStaging = "staging";
    // }

    const [tokenContent, setTokenContent]   = useState(null);
    const [loading, setLoading]             = useState(true);
    const [error, setError]                 = useState("");
    const [branding, setBranding]           = useState('default');

    // This function is a temporary solution that needs to be worked on when
    // backend API has a feature where API response for validateToken can
    // supply IconURL of the client accordingly.
    // Currently, API respones will just send a demo url 'https://somthing.iprsaps.com/client-icon.png'
    // and when it receives this string, it will set the branding to Aviva, 
    const brandHandler = (IconURL) => {
        if(IconURL === 'https://somthing.iprsaps.com/client-icon.png'){
            setBranding('Aviva')
        } else {
            setBranding('default')
        }
    }

    // State to store branding information
    const [brandingInfo, setBrandingInfo] = useState({});

    const getBrandingInfo = (CaseID) => {
        const params = {
            "CaseID": CaseID
        }

        setLoading(true);
        api.transact("GetBrandingInfo", params)
            .then(r=>{
                setLoading(false);
                setBrandingInfo(r.apiResult.Settings);
            })
            .catch(e=>{
                setError(e.message);
            })
    }

    useEffect(() => {
        if(null === api || undefined === api){
            setError("API is not provided");
            return;
        }
        if(null === getURLSearchParam || undefined === getURLSearchParam
            || null === getURLPathArray || undefined === getURLPathArray){
            setError("getURLSearchParam is not provided or getURLPathArray is not provided");
            return;
        }
        let urlToken = getURLSearchParam('Token');      
        // No token in the page URL? see if we have one in storage
        urlToken = urlToken ? urlToken : api.getToken(); 

        const isReturn = getURLPathArray().includes('Return');

        if (isReturn || !urlToken) {
            setLoading(false);
            // When there is no token in the URL, or the URL is a return URL, 
            // the subpage will display the error
            setTokenContent({ Error: 'No Token' });
        } else {

            setLoading(true);
            api.validateLoggedIn(urlToken) // check existing stored
                .then(token => {
                    api.setToken(token);
                    setLoading(false);

                    brandHandler("");
                    
                    const decodedToken = decodeJWT(token);
                
                    setTokenContent(decodedToken);

                    /* Only load branding info if the token has CaseID
                    this is not the case with aviva ReferralForms as the case is 
                    provisional. In that case we can't yet do branding config */
                    if(decodedToken.CaseID){
                        // double check as a dotnet4 SaveAvivaReferralForm will have a CaseID
                        const isReferralForm = getURLPathArray().includes('ReferralForm');
                        if(!isReferralForm){
                            getBrandingInfo(decodedToken.CaseID);
                        }
                    }
                    })
                .catch(e => {
                    const eMessage = e.message;
                    const networkOK = !(/No connection could be made/i.test(e.message));
                    const revocation = /revoked/i.test(e.message);
                    let message;
                    if(revocation){
                        message = "The link you have clicked has been used already.";
                    }else{
                        message = networkOK? eMessage: "Sorry, there was a network error.";
                    }
    
                    setError(message);
                    setLoading(false);
                });
        } 
        // eslint-disable-next-line
    }, [api, getURLSearchParam]);

    synthetix();

    return (
        <div >
            <AppHeader branding={branding} brandingInfo={brandingInfo} />
            <TwoSwitch test={error} >
                <div className="App">
                    <div className='error'>
                        <h1>Sorry. There was an error</h1>
                        <p>Thanks for accessing the IPRS Health Physiotherapy Assessment Portal today.<br />
                        We are sorry there was an error:- </p>
                        <p>{error}</p>
                        <p>Please contact the Aviva Claims Team on 0800 096 1542 for further assistance.</p>
                    </div>
                </div>
                <TwoSwitch test={loading} >
                    <Spinner />
                    <AppRoutes
                        api={api}
                        tokenContent={tokenContent}
                        branding={branding}
                        brandingInfo={brandingInfo}
                    />
                </TwoSwitch>
            </TwoSwitch>
            <AppFooter />
        </div>
    );
}

const Spinner = () => {

    const loaderCssOverride = {
        borderColor: "rgb(255 210 239 / 66%) rgb(255 210 239 / 66%) #dd6cae"
    }

    return <div className="loader">
        <LoadingIndicator loading={true} color={'#BD5196'} speed={0.5} size={42} cssOverride={loaderCssOverride} />

        <p style={{ marginTop: '26px' }}>Please wait...</p>
    </div>;
}

export default App;
